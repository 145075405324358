
(function( $ ) {

    window.scrollingToHash = false;
    function scroll_to_hash(hash,url){
      if(!window.scrollingToHash){
        window.scrollingToHash = true;
        var target = $(hash),
        target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
        if (target.length) {
          $('html,body').animate({
            scrollTop: target.offset().top
          }, 800,function(){
            window.scrollingToHash = false;
            if(history.pushState) {
                history.pushState({'url':url},"",url);
            }
          });
        }
      }
    }

    $(function() {
      $('a[href*=\\#]:not([href=\\#])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
          var target = $(this.hash);
          scroll_to_hash(target,this.hash);
          return false;
        }
      });
    });

    window.onload = function() {
        $(document).foundation();
        if(window.location.hash) {
            scroll_to_hash(window.location.hash);
        }
    }

})( jQuery );